import React from 'react';
import './App.css';
import Home from "./views/Home";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PrivacyPolicy from "./views/PrivacyPolicy";

function App() {
  return (
    <Router>
      <Route exact path="/" component={Home} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/copyright-policy" component={PrivacyPolicy} />
      <Route path="/tos" component={PrivacyPolicy} />
    </Router>
  );
}

export default App;
