import React from "react"
import appLogo from "../../assets/app_logo.svg";
import Container from "reactstrap/es/Container";

export default class PrivacyPolicy extends React.Component {

  render() {
    return (
      <div className="App">
        <header className="App-header">

          <img src={appLogo} className="App-logo" alt="logo"/>
          <h1>Themes Manager</h1>

          <h2>Themes Manager Copyright Policy</h2>


          <Container>
            <text>Hello, and thank you for visiting Themes Manager's copyright policy page.</text>


            <text>Themes Manager respects intellectual property rights and expects its users to do the same. If you are a
              copyright holder, or its agent, and you believe that any of the copyrighted material which is directly
              available via the Themes Manager Service infringes your copyrighted work, please let us know.</text>


            <p>Please send us an email to submit a notice of alleged copyright infringement.</p>

            <b>labdeishelon@gmail.com</b>

          </Container>
        </header>
      </div>
    );
  }
}